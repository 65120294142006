@import url('https://fonts.googleapis.com/css?family=Dokdo|Nanum+Brush+Script|ZCOOL+KuaiLe');

@import '~sassync/sass/_default/import';

$ZCOOL: 'ZCOOL KuaiLe',
cursive;
$Nanum: 'Nanum Brush Script',
cursive;
$Dokdo: 'Dokdo',
cursive;

$bg-color-dark: #000;
$bg-color-light: #efefef;

body {
  // background-color: $bg-color-dark;
}

.text {
  &--trick {
    font-size: 36px;
    font-family: $Nanum;
    color: #fa4e40;
  }

  &--say {
    margin: 0 0 20px 0;
    font-size: 26px;
    font-family: $Nanum;
    color: #fff;
  }
}

.schema {
  transition: all .125s ease-in;
  &--light {
    background-color: $bg-color-light;


    .text {
      &--say {
        color: $bg-color-dark;
      }
    }
  }

  &--dark {
    background-color: $bg-color-dark;
  }
}

.change-schema {
  @include flex-all;
  margin: 20px 0 30px 0;

  .btn {
    @include flex-all;
    border: 2px solid transparent;
    @include size(20px);
    font-size: 0;
    cursor: pointer;

    &--light {
      border-color: $bg-color-dark;
      background-color: $bg-color-light;
      transition: all .125s ease;

      &--active {
        border-color: $bg-color-light;
      }

    }

    &--dark {
      margin-left: 5px;
      border-color: $bg-color-light;
      background-color: $bg-color-dark;
      transition: all .125s ease;

      &--active {
        border-color: $bg-color-dark;
      }
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  @include max-min(max 768px) {
  height: 95vmin;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  margin: 15px 0;
}