@import url(https://fonts.googleapis.com/css?family=Dokdo|Nanum+Brush+Script|ZCOOL+KuaiLe);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text--trick {
  font-size: 36px;
  font-family: "Nanum Brush Script", cursive;
  color: #fa4e40; }

.text--say {
  margin: 0 0 20px 0;
  font-size: 26px;
  font-family: "Nanum Brush Script", cursive;
  color: #fff; }

.schema {
  transition: all .125s ease-in; }
  .schema--light {
    background-color: #efefef; }
    .schema--light .text--say {
      color: #000; }
  .schema--dark {
    background-color: #000; }

.change-schema {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 20px 0 30px 0; }
  .change-schema .btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border: 2px solid transparent;
    width: 20px;
    height: 20px;
    font-size: 0;
    cursor: pointer; }
    .change-schema .btn--light {
      border-color: #000;
      background-color: #efefef;
      transition: all .125s ease; }
      .change-schema .btn--light--active {
        border-color: #efefef; }
    .change-schema .btn--dark {
      margin-left: 5px;
      border-color: #efefef;
      background-color: #000;
      transition: all .125s ease; }
      .change-schema .btn--dark--active {
        border-color: #000; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin;
  pointer-events: none; }
  @media only screen and (max-width: 768px) {
    .App-logo {
      height: 95vmin; } }

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

p {
  margin: 15px 0; }

